import {css, html} from 'lit-element';
import "../components/form/InputComponent";
import "../components/form/TextareaComponent";
import "../components/form/CheckboxComponent";
import {Form} from "../core/Form";
import isEmpty from "validator/es/lib/isEmpty";
import isEmail from "validator/es/lib/isEmail";
import axios from "axios";


/**
 * Contact form
 */
export class NewsletterComponent extends Form {

    static get styles() {
        return css``;
    }

    static get properties() {
        return {
            form: {type: Object, attribute: false},
            formErrors: {type: Object, attribute: false},
            rules: {type: Object, attribute: false},
            message: {type: Object},
            showReset: {type: Object},
        };
    }

    constructor() {
        super();

        this.form = {
            email: '',
        };

        this.rules = {
            email: [
                {validator: isEmpty, compare: false, message: 'Adres email jest wymagany'},
                {validator: isEmail, compare: true, message: 'Wartość nie jest prawidłowa'}
            ]
        }
        this.events();
    }

    hasError(attr) {
        return Object.prototype.hasOwnProperty.call(this.formErrors, attr);
    }

    createRenderRoot() {
        return this;
    }

    attributeChangedCallback(name, oldval, newval) {
        console.log('NewsletterComponent attribute change: ', name, newval);
        super.attributeChangedCallback(name, oldval, newval);
    }

    // eslint-disable-next-line no-unused-vars
    firstUpdated(_changedProperties) {
        console.log('label:', this.label);
    }

    resetForm() {
        this.form = {
            email: '',
        };
    }

    events() {

    }

    async fetchData() {

    }

    _onFormSubmit() {

        if (this.validate()) {
            console.log('sending', this.form);

            axios.post('/api/subscribers/register-subscriber', this.form).then(r => {
                console.log('response:', r);
                this.setMessage({
                    content: "Twoja wiadomość została wysłana, dziękujemy za kontakt.",
                    type: 'success'
                });
                this.resetForm();

            }).catch(e => {
                console.log('catched:', e.response);
                this.processResponseError(e.response)
            });
        } else {
            this.setMessage({
                content: "Wystąpił problem z formularzem.",
                type: 'error'
            });
        }
    }

    _onFormReset() {
        this.resetForm();
    }

    _onChange(e, attr) {
        console.log('target:', e);
        this.form[attr] = e.detail;
        this.validate();
    }

    render() {
        return html`
            <div class="flex justify-center items-center m-auto my-8 w-full">
                <div class="flex flex-col lg:flex-row lg:w-7/12 xl:w-7/12 xl:flex-row justify-center items-center w-11/12 h-32 rounded-md bg-blueGray-700">
                    <div class="flex flex-col p-2 lg:p-4 xl:p-4">
                        <h3 class="text-2xl font-extrabold text-blueGray-100 ">Newsletter</h3>
                        <p class="ml-1 text-sm text-blueGray-100 ">Zapisz się i bądź na bieżąco z naszymi nowościami</p>
                    </div>
                    <div class="flex p-2 lg:p-4 xl:p-4">
                        <div class="mr-2">
                            <cn-input
                                    @value-change="${(e) => this._onChange(e, 'email')}"
                                    value="${this.form.email}"
                                    .label="${false}"
                                    placeholder="adres e-mail"
                            >
                            </cn-input>
                            <span class="text-rose-700">${this.hasError('email') ? html`${this.formErrors.email}` : ``}</span>
                        </div>
                        <button
                                @click="${this._onFormSubmit}"
                                class="w-32 h-10 text-sm font-extrabold text-white bg-blue-400 rounded-md hover:bg-blue-500"
                        >
                            Wyślij
                        </button>
                        ${this.showReset ? html`
                            <button
                                    @click="${this._onFormReset}"
                                    class="w-32 h-10 text-sm font-extrabold text-white bg-blue-400 rounded-md hover:bg-blue-500"
                            >
                                Reset
                            </button>` : ''}
                    </div>

                </div>
            </div>
            ${(this.message.content && this.message.content.length) ? html`
                <div class="fixed top-0 right-2 bg-teal-50 border-t-4 border-teal rounded-b text-teal-darkest px-4 py-3 shadow-md"
                     role="alert">
                    <div class="flex">
                        <svg class="h-6 w-6 text-teal mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                        </svg>
                        <div>
                            <p class="font-bold"></p>
                            <p class="text-sm">${this.message.content}</p>
                        </div>
                    </div>
                </div>
            ` : ''}
        `;
    }

    _onClick() {
    }
}

window.customElements.define('cn-newsletter-component', NewsletterComponent);
