/**
 * Contact form
 */
import {LitElement} from "lit-element";
import {NoFormException} from "../errors/FormExceptions";

export class Form extends LitElement {

    static get properties() {
        return {
            form: {type: Object, attribute: false},
            formErrors: {type: Object, attribute: false},
            rules: {type: Object, attribute: false},
            message:{type: Object},
            showReset:{type: Object},
        };
    }

    constructor() {
        super();
        this.formErrors = {};
        this.form = {};
        this.rules = {}
        this.showReset = false;
        this.message = {
            content: '',
            type: 'success'
        }
    }

    attributeChangedCallback(name, oldval, newval) {
        console.log('Form attribute change: ', name, newval);
        super.attributeChangedCallback(name, oldval, newval);
    }


    /**
     * Validate client data
     * @return {boolean}
     */
    validate() {
        this.removeMessage();
        if (Object.getOwnPropertyNames(this.form).length === 0) {
            throw new NoFormException(this);
        }
        this.formErrors = {};
        console.log('form:', this.form);
        for (const [key, value] of Object.entries(this.form)) {
            const rule = this.rules[key]
            if (rule) {
                if (Array.isArray(rule)) {
                    rule.forEach(r => this.processRule(r, key, value));
                } else {
                    this.processRule(rule, key, value);
                }
            }
            console.log('rule:', rule);
        }
        return (Object.getOwnPropertyNames(this.formErrors).length === 0);
    }

    /**
     *
     * @param response
     */
    processResponseError(response) {
        this.removeMessage();
        if (Object.prototype.hasOwnProperty.call(response, "status")) {
            if (response.status === 422) {
                if (Object.prototype.hasOwnProperty.call(response, "data")) {
                    if (Array.isArray(response.data.detail)) {
                        response.data.detail.forEach(r => {
                            this.message  = {
                                content: r.msg ?? '',
                                type: 'error'
                            }
                        });
                    }

                }
            }

        }
    }

    /**
     *
     * @param {Object} rule
     * @param {String} key
     * @param value
     */
    processRule(rule, key, value) {
        console.log('processRule:', rule, key, value)

        if (rule.validator) {
            const checkValue = String(value);
            if (rule.validator(checkValue) !== rule.compare) {
                this.formErrors[key] = rule.message ?? 'Zła wartość';
            }
        } else {
            if (value !== rule.compare) {
                this.formErrors[key] = rule.message ?? 'Zła wartość';
            }
        }
    }

    /**
     *
     * @param {Object} data
     */
    setMessage(data) {
        this.message = data
        setTimeout(() => { this.removeMessage(); }, 3000);
    }

    removeMessage() {
        this.message = {
            content: '',
            type: 'success'
        }
    }



}
