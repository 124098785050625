import {css, html, LitElement} from 'lit-element';
import "../components/form/InputComponent";
import "../components/form/TextareaComponent";
import "../components/form/CheckboxComponent";
import {Form} from "../core/Form";
import isEmpty from "validator/es/lib/isEmpty";
import isEmail from "validator/es/lib/isEmail";
import axios from "axios";


/**
 * Contact form
 */
export class ContactFormComponent extends Form {

    static get styles() {
        return css``;
    }

    constructor() {
        super();

        this.form = {
            name: '',
            email: '',
            content: '',
            rodo: '',
        };

        this.rules = {
            name: {validator: isEmpty, compare: false, message: 'Wartość wymagana'},
            email: [
                {validator: isEmpty, compare: false, message: 'Adres email jest wymagany'},
                {validator: isEmail, compare: true, message: 'Wartość nie jest prawidłowa'}
            ],
            content: {validator: isEmpty, compare: false, message: 'Treść wiadomości jest wymagana'},
            rodo: {compare: true, message: 'Zaakceptuj'},
        }
        this.events();
    }

    hasError(attr) {
        return Object.prototype.hasOwnProperty.call(this.formErrors, attr);
    }

    createRenderRoot() {
        return this;
    }

    attributeChangedCallback(name, oldval, newval) {
        //console.log('ContactFormComponent attribute change: ', name, newval);
        super.attributeChangedCallback(name, oldval, newval);
    }

    // eslint-disable-next-line no-unused-vars
    firstUpdated(_changedProperties) {

    }

    resetForm() {
        this.form = {
            name: '',
            email: '',
            content: '',
            rodo: '',
        };

    }

    events() {

    }

    async fetchData() {

    }

    _onFormSubmit() {

        if (this.validate()) {
            console.log('sending', this.form);

            axios.post('/forms/send-contact', this.form).then(r => {
                this.message = {
                    content: "Twoja wiadomość została wysłana, dziękujemy za kontakt.",
                    type: 'success'
                }
            }).catch(e => {
                console.log('Response error: ', e.response);
                this.processResponseError(e.response)
            });
        } else {
            this.setMessage({
                content: "Wystąpił problem z formularzem.",
                type: 'error'
            });
        }
    }

    _onChange(e, attr) {
        console.log('Contact Form _onChange:', e, attr, e.target.value, this.form);
        this.form[attr] = e.detail ? e.detail : e.target.value;
        this.validate();
    }

    render() {
        return html`
            <section>
                <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900 border-b pb-5 mb-5 border-gray-200">
                    Kontakt</h1>
                <div class="bg-white relative flex flex-wrap pb-16">
                  <div class="lg:w-1/2 px-6">
                    <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">Adres</h2>
                    <p class="mt-1">MfDesign</p>
                    <p class="mt-1">Fronty meblowe</p>
                    <p class="mt-1">ul. Drogowców 14</p>
                    <p class="mt-1">39-200 Dębica</p>
                </div>
                <div class="lg:w-1/2 px-6 mt-4 lg:mt-0">
                    <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs">E-MAIL</h2>
                    <a class="text-indigo-500 leading-relaxed">frontymeblowe@interia.pl</a>
                    <h2 class="title-font font-semibold text-gray-900 tracking-widest text-xs mt-4">Telefony</h2>

                    <p class="leading-relaxed">606 110 531 <strong>Michał</strong></p>
                    <p class="leading-relaxed">791 583 255 <strong>Tomek</strong></p>
                </div>

                </div>
                
                <p class="leading-relaxed mb-5 text-gray-600">Napisz do nas.</p>

                <div class="relative mb-4">
                    <cn-input @value-change="${(e) => this._onChange(e, 'name')}"
                              value="${this.form.name}"
                              label="Imię i nazwisko">

                    </cn-input>
                    <span class="text-rose-700">${this.hasError('name') ? html`${this.formErrors.name}` : ``}</span>
                </div>
                <div class="relative mb-4">
                    <cn-input @value-change="${(e) => this._onChange(e, 'email')}"
                              value="${this.form.email}"
                              label="Adres e-mail"
                              input-type="email">
                    </cn-input>
                    <span class="text-rose-700">${this.hasError('email') ? html`${this.formErrors.email}` : ``}</span>
                </div>
                <div class="relative mb-4">
                    <cn-textarea
                            label="Treść wiadomości"
                            @value-change="${(e) => this._onChange(e, 'content')}"
                            value="${this.form.content}"
                    ></cn-textarea>
                    <span class="text-rose-700">${this.hasError('content') ? html`${this.formErrors.content}` : ``}</span>
                </div>
                <div class="relative mb-4">
                    <cn-checkbox
                            label="Wysyłając formularz wyrażam z zgodę na przetwarzanie swoich danych osobowych zgodnie z ustawą o RODO"
                            @value-change="${(e) => this._onChange(e, 'rodo')}"
                            value="${this.form.rodo}"
                    ></cn-checkbox>
                    <span class="text-rose-700">${this.hasError('rodo') ? html`${this.formErrors.rodo}` : ``}</span>
                </div>

                <button @click="${this._onFormSubmit}"
                        type="submit"

                        class="text-white bg-blueGray-700 border-0 py-2 px-6 focus:outline-none hover:bg-blueGray-500 rounded text-lg"
                >
                    Wyślij
                </button>
            </section>

            ${this.message.content.length ? html`
                <div class="fixed top-0 right-2 bg-teal-50 border-t-4 border-teal rounded-b text-teal-darkest px-4 py-3 shadow-md"
                     role="alert">
                    <div class="flex">
                        <svg class="h-6 w-6 text-teal mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                        </svg>
                        <div>
                            <p class="font-bold"></p>
                            <p class="text-sm">${this.message.content}</p>
                        </div>
                    </div>
                </div>
            ` : ''}

        `;
    }

    _onClick() {
    }
}

window.customElements.define('cn-contact-form', ContactFormComponent);
