import {css, html, LitElement} from 'lit-element';
import '@spectrum-web-components/picker/sp-picker.js';
import '@spectrum-web-components/menu/sp-menu-item.js';
import '@spectrum-web-components/icon/sp-icon.js';
import colors from './data/ral';
import "../spectrum/ColorMenuItem";

/**
 * An input element.
 */
class RalSelectComponent extends LitElement {

    static get styles() {
        return css``;
    }

    static get properties() {
        return {
            label: {type: String | Boolean},
            placeholder: {type: String},
            type: {type: String},
            value: {type: String}
        };
    }

    constructor() {
        super();
        this.type = 'text';
        this.value = '';
        this.placeholder = '';
        this.colors = colors;

        this.events();
    }

    attributeChangedCallback(name, oldval, newval) {
        console.log('InputComponent attribute change: ', name, newval);
        super.attributeChangedCallback(name, oldval, newval);
    }

    createRenderRoot() {
        return this;
    }

    // eslint-disable-next-line no-unused-vars
    firstUpdated(_changedProperties) {
        console.log('colors:', this.colors);
    }

    events() {

    }
    _onChange(e) {
        let change = new CustomEvent('change', {detail: e.target.value});
        this.dispatchEvent(change);
    }

    async fetchData() {

    }

    render() {
        return html`

            <sp-field-label for="picker-item-text">${this.label}</sp-field-label>
            <sp-picker
                    @change="${this._onChange}"
                    label="${this.label}"
                    .value="${this.value}" 
                    style="width: 100%"
            >
                ${this.colors.map(i => html`
                    <sp-color-menu-item value="${i.RAL}" color="${i.HEX}">${i.RAL}</sp-color-menu-item>
                `)}

            </sp-picker>

        `;
    }

    _onClick() {

    }

}


window.customElements.define('cn-ral-select', RalSelectComponent);
