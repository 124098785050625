import {css, html, LitElement} from 'lit-element';


/**
 * A checkbox element.
 */
class CheckboxComponent extends LitElement {

    static get styles() {
        return css``;
    }

    static get properties() {
        return {
            label: {type: String},
            type: {type: String},
            value: {type: String, reflect: true}
        };
    }

    constructor() {
        super();
        this.type = 'checkbox';
        this.value = '';
        this.events();
    }

    attributeChangedCallback(name, oldval, newval) {
        super.attributeChangedCallback(name, oldval, newval);
    }

    createRenderRoot() {
        return this;
    }

    // eslint-disable-next-line no-unused-vars
    firstUpdated(_changedProperties) {

    }

    events() {

    }

    _onInput(e) {
        this.value = e.target.checked;
        console.log('on input: ', e.target.checked);
        let change = new CustomEvent('value-change');
        this.dispatchEvent(change);
    }

    _onChange(e) {
        this.value = e.target.checked;
        console.log('on change: ', e.target.checked);
    }

    async fetchData() {

    }

    render() {
        return html`
            <label class="inline-flex items-center">
                <input 
                        class="form-checkbox" 
                        type="${this.type}" 
                        @input="${this._onInput}"
                        @change="${this._onChange}"
                >
                <span class="ml-2">${this.label}</span>
            </label>
        `;
    }

    _onClick() {

    }

}

window.customElements.define('cn-checkbox', CheckboxComponent);
