import {css, html, LitElement} from 'lit-element';

/**
 * An input element.
 */
class InputComponent extends LitElement {

    static get styles() {
        return css``;
    }

    static get properties() {
        return {
            label: {type: String|Boolean},
            placeholder: {type: String},
            inputType: {type: String},
            value: {type: String}
        };
    }

    constructor() {
        super();
        this.inputType = 'text';
        this.value = '';
        this.placeholder = '';

        this.events();
    }

    attributeChangedCallback(name, oldval, newval) {
        console.log('InputComponent attribute change: ', name, newval);
        super.attributeChangedCallback(name, oldval, newval);
    }

    createRenderRoot() {
        return this;
    }
    // eslint-disable-next-line no-unused-vars
    firstUpdated(_changedProperties) {

    }
    events() {

    }
    _onInput(e) {

        //this.value = e.target.value;

        let change = new CustomEvent('value-change', {detail: e.target.value});
        console.log('dispatching data:', e.target.value);
        this.dispatchEvent(change);
    }

    _onChange(e) {
        //console.log('on change:', e, this.value);
        //this.val = e.target.value;
        //let change = new Event('change');

        //this.dispatchEvent(change);
    }

    async fetchData() {

    }

    render() {
        return html`
            
            ${this.label ? html`<label for="name" class="leading-7 text-sm text-gray-600">${this.label}</label>`:''}
            <input
                    @input="${this._onInput}"
                    @change="${this._onChange}"
                    type="${this.inputType}"
                    name="name"
                    .value="${this.value}"
                    placeholder="${this.placeholder}"
                    class="w-full h-10 bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
            >
            
        `;
    }

    _onClick() {

    }
    
}

window.customElements.define('cn-input', InputComponent);
