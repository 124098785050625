import {MenuItem} from '@spectrum-web-components/menu';
import {html} from '@spectrum-web-components/base';
import '@spectrum-web-components/icon/sp-icon.js';
import {CircleIcon} from '@spectrum-web-components/icons-workflow';

export class ColorMenuItem extends MenuItem {

    static get properties() {
        return {
            color: {type: String},
        };
    }
    constructor() {
        super();
        this.color = 'red';
    }

    get buttonContent() {
        const content = super.buttonContent;
        content.push(html`
            <sp-icon style="color: ${this.color};">
                ${CircleIcon()}
            </sp-icon>
        `);
        return content;
    }

}

customElements.define('sp-color-menu-item', ColorMenuItem);