export function NoFormException(value) {
    this.value = value;
    this.message = 'Form class has to implement form attribute.';
    this.toString = function() {
        return this.message;
    };
}

export function NoRulesException(value) {
    this.value = value;
    this.message = 'Form class has to implement rules attribute.';
    this.toString = function() {
        return this.message;
    };
}