import {css, html, LitElement} from 'lit-element';

/**
 * A textarea element.
 */
class TextareaComponent extends LitElement {

    static get styles() {
        return css``;
    }

    static get properties() {
        return {
            label: {type: String},
            name: {type: String},
            type: {type: String, default: 'text'},
            value: {type: String, reflect: true}
        };
    }

    constructor() {
        super();
        this.name = 'message';
        this.events();
    }

    createRenderRoot() {
        return this;
    }

    // eslint-disable-next-line no-unused-vars
    firstUpdated(_changedProperties) {

    }

    _onInput(e) {
        //console.log('on input1:', e, this.value);
        //console.log('on input2:', e.target.value);
        this.value = e.target.value;
        let change = new CustomEvent('value-change');
        //console.log('disaptching change:', change);
        this.dispatchEvent(change);
    }
    events() {

    }

    async fetchData() {

    }

    render() {
        return html`
            <label for="message" class="leading-7 text-sm text-gray-600">${this.label}</label>
            <textarea
                    name="${this.name}"
                    @input="${this._onInput}"
                    class="w-full bg-white rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
            ></textarea>
        `;
    }

    _onClick() {

    }
}

window.customElements.define('cn-textarea', TextareaComponent);
