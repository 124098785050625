import {html, live, css} from '@spectrum-web-components/base';
import {Form} from "../core/Form";
import isEmpty from "validator/es/lib/isEmpty";
import isEmail from "validator/es/lib/isEmail";
import axios from "axios";
import '@spectrum-web-components/textfield/sp-textfield.js';
import '@spectrum-web-components/field-label/sp-field-label.js';
import '@spectrum-web-components/checkbox/sp-checkbox.js';
import "./spectrum/StepperComponent"

/**
 * Contact form
 */
export class FurnitureFrontCalculator extends Form {

    static get styles() {
        return css``;
    }

    static get properties() {
        return {
            form: {type: Object, attribute: false},
            formErrors: {type: Object, attribute: false},
            rules: {type: Object, attribute: false},
            message: {type: Object},
            showReset: {type: Object},
            productId: {type: String}
        };
    }

    constructor() {
        super();

        this.product = {};

        this.form = {
            width: "100",
            height: "100",
            quantity: "1",
            email: '',
            phone: '',
            color: '',
            finish: '',
            agree: true,
            content: '',
        };

        this.rules = {
            width: {validator: isEmpty, compare: false, message: 'Wartość wymagana'},
            height: {validator: isEmpty, compare: false, message: 'Wartość wymagana'},
            phone: {validator: isEmpty, compare: false, message: 'Wartość wymagana'},
            agree: {compare: true, message: 'Akceptacja wymagana'},
            email: [
                {validator: isEmpty, compare: false, message: 'Adres email jest wymagany'},
                {validator: isEmail, compare: true, message: 'Wartość nie jest prawidłowa'}
            ],
            quantity: {validator: isEmpty, compare: false, message: 'Ilość jest wymagana'}
        }
        this.events();
    }


    hasError(attr) {
        return Object.prototype.hasOwnProperty.call(this.formErrors, attr);
    }

    createRenderRoot() {
        return this;
    }

    attributeChangedCallback(name, oldval, newval) {
        //console.log('ContactFormComponent attribute change: ', name, newval);
        super.attributeChangedCallback(name, oldval, newval);
    }

    // eslint-disable-next-line no-unused-vars
    firstUpdated(_changedProperties) {
        //console.log('label:', this.label);
        console.log('product id:', this.productId);

    }

    resetForm() {
        this.form = {
            width: "100",
            height: "100",
            quantity: "1",
            email: '',
            phone: '',
            color: '',
            finish: '',
            agree: true,
            content: '',
        };
    }

    events() {

    }

    async fetchData() {

    }

    _onFormSubmit() {
        console.log('trying to send', this.form);
        if (this.validate()) {
            //     console.log('sending', this.form);
            //
            this.form.product_id = this.productId;
            axios.post('/api/order/send-order-valuation', this.form).then(r => {
                console.log('response:', r);
                this.message = {
                    content: "Twoja wiadomość została wysłana, dziękujemy za kontakt.",
                    type: 'success'
                }
                this.resetForm();

            }).catch(e => {
                console.log('catched:', e.response);
                this.processResponseError(e.response)
            });
        } else {
            this.setMessage({
                content: "Wystąpił problem z formularzem.",
                type: 'error'
            });
        }
    }

    _onFormReset() {
        this.resetForm();
    }

    _onCheckboxChange(e, attr) {
        console.log('_onChange:', attr, e.target.checked, this.form);
        this.form[attr] = e.target.checked;
        this.validate();
    }

    _onChange(e, attr) {
        console.log('Furniture front calc _onChange:', e, attr, e.target.value, this.form);
        this.form[attr] = e.detail ? e.detail : e.target.value;
        this.validate();
    }

    render() {
        return html`
            <div class="flex mt-6 items-center pb-5 border-b-2 border-gray-100 mb-5"></div>
            <h2 class="text-gray-900 text-lg mb-1 font-medium title-font">Konfiguracja zamówienia</h2>
            <div class="grid grid-cols-2 gap-4">
                <div>
                    <sp-field-label for="name-0">Szerokość</sp-field-label>
                    <sp-textfield
                            placeholder=""
                            .value="${live(this.form.width)}"
                            style="width: 100%"
                            @input="${(e) => this._onChange(e, 'width')}"
                    ></sp-textfield>
                    <span class="text-rose-700">${this.hasError('email') ? html`${this.formErrors.width}` : ``}</span>
                </div>
                <div>
                    <sp-field-label for="name-1">Wysokość</sp-field-label>
                    <sp-textfield
                            placeholder=""
                            value="100"
                            style="width: 100%"
                            @input="${(e) => this._onChange(e, 'height')}"
                    ></sp-textfield>
                    <span class="text-rose-700">${this.hasError('email') ? html`${this.formErrors.height}` : ``}</span>
                </div>
            </div>
            <div class="grid grid-cols-2 gap-4">
                <div>
                    <cn-ral-select
                            label="Kolor"
                            @change="${(e) => this._onChange(e, 'color')}"
                            .value="${live(this.form.color)}"
                            style="width: 100%"></cn-ral-select>
                </div>
                <div>
                    <sp-field-label for="picker-value">Wykończenie:</sp-field-label>
                    <sp-picker
                            label=""
                            @change="${(e) => this._onChange(e, 'finish')}"
                            .value="${live(this.form.finish)}"
                            style="width: 100%"
                    >
                        <sp-menu-item value="Połysk">Połysk</sp-menu-item>
                        <sp-menu-item value="Mat">Mat</sp-menu-item>
                        <sp-menu-item value="Półmat">Półmat</sp-menu-item>
                        <sp-menu-item value="Brokat">Brokat</sp-menu-item>
                        <sp-menu-item value="Perła">Perła</sp-menu-item>
                    </sp-picker>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-4">
                <div>
                    <sp-field-label>Ilość</sp-field-label>
                    <sp-stepper
                            .value="${this.form.quantity}"
                            @input="${(e) => this._onChange(e, 'quantity')}"
                            style="width: 100%"
                    >

                    </sp-stepper>
                    <span class="text-rose-700">${this.hasError('quantity') ? html`${this.formErrors.quantity}` : ``}</span>
                </div>
                <div>

                </div>
            </div>


            <div class="grid grid-cols-2 gap-4">
                <div>
                    <sp-field-label>E-mail</sp-field-label>
                    <sp-textfield
                            placeholder=""
                            .value="${live(this.form.email)}"
                            style="width: 100%"
                            @input="${(e) => this._onChange(e, 'email')}"
                    ></sp-textfield>
                    <span class="text-rose-700">${this.hasError('email') ? html`${this.formErrors.email}` : ``}</span>

                </div>
                <div>

                    <sp-field-label>Telefon</sp-field-label>
                    <sp-textfield
                            placeholder=""
                           
                            style="width: 100%"
                            .value="${live(this.form.phone)}"
                            @input="${(e) => this._onChange(e, 'phone')}"
                    ></sp-textfield>
                    <span class="text-rose-700">${this.hasError('phone') ? html`${this.formErrors.phone}` : ``}</span>

                </div>
            </div>
            <div class="grid grid-cols-1">
                <div>
                    <sp-field-label>Dodakowe informacje</sp-field-label>
                    <sp-textfield
                            style="width: 100%"
                            multiline
                            placeholder="Inne informacje dotyczące zamówienia"
                            .value="${live(this.form.content)}"
                            @input="${(e) => this._onChange(e, 'content')}"
                    ></sp-textfield>
                </div>
            </div>

            <div class="grid grid-cols-1">
                <div>
                    <sp-checkbox
                            size="m"
                            ?checked="${live(this.form.agree)}"
                            @change="${(e) => this._onCheckboxChange(e, 'agree')}"
                    >
                        Wyrażam zgodę na przetwarzanie moich danych osobowych zgodnie z ustawą o ochronie danych
                        osobowych w celu przesyłania informacji handlowej drogą elektroniczną zgodnie z ustawą o
                        świadczeniu usług drogą elektroniczną na podany w tym celu adres email lub numer telefonu.
                        Podanie danych osobowych jest dobrowolne. Zostałem /am poinformowany /a, że przysługuje mi prawo
                        dostępu do swoich danych, możliwości ich poprawiania, żądania zaprzestania ich przetwarzania.
                        Administratorem danych jest firma MF Design.
                    </sp-checkbox>
                    <span class="text-rose-700">${this.hasError('agree') ? html`${this.formErrors.agree}` : ``}</span>
                </div>
            </div>


            <div class="flex mt-6 items-center pb-5 border-b-2 border-gray-100 mb-5">
            </div>
            <button @click="${this._onFormSubmit}"
                    class="text-white bg-blueGray-700 border-0 py-2 px-6 focus:outline-none hover:bg-blueGray-500 rounded text-lg">
                Wyślij zapytanie
            </button>
            ${(this.message.content && this.message.content.length) ? html`
                <div class="fixed top-0 right-2 bg-teal-50 border-t-4 border-teal rounded-b text-teal-darkest px-4 py-3 shadow-md"
                     role="alert">
                    <div class="flex">
                        <svg class="h-6 w-6 text-teal mr-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                            <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z"/>
                        </svg>
                        <div>
                            <p class="font-bold"></p>
                            <p class="text-sm">${this.message.content}</p>
                        </div>
                    </div>
                </div>
            ` : ''}
        `;
    }

    _onClick() {
    }
}

window.customElements.define('cn-furniture-front-calculator', FurnitureFrontCalculator);
