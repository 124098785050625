import axios from "axios";
import {LitElement, html, css} from 'lit-element';
import 'fslightbox';

/**
 * An gallery element.
 */
export class GalleryComponent extends LitElement {


    static get styles() {
        return css``;
    }

    static get properties() {
        return {
            slides: {type: Array},
            categoryId: {attribute: 'category-id'}
        };
    }

    constructor() {
        super();
        this.slides = [];
        this.categoryId = null;
        this.events();
    }

    createRenderRoot() {
        return this;
    }

    async firstUpdated(_changedProperties) {

        super.firstUpdated(_changedProperties);
        await this.fetchData();
        this.lightbox = new FsLightbox();
        this.lightbox.props.sources = this.slides.map(r => r.url_full_path);


        console.log('Category id:', this.categoryId);

    }

    events() {
        this.galleryElement = document.querySelector('.gallery-container');
        this.galleryElement.addEventListener('click', (e) => {
            e.preventDefault();
            console.log('gallery element clicked:', e);
            this.lightbox.open();
            this._onModalToggle();

            //store.dispatch('mobileMenuClicked');
        });
    }

    async fetchData() {
        const url = this.categoryId ? `/media-category/${this.categoryId}/json` : "/media";
        const response = await axios.get(url);
        this.slides = response.data;
        console.log('fetched media objects: ', this.slides);
    }

    render() {
        return html`

        `;
    }

    _onClick() {
        this.count++;
    }

    _onModalToggle() {

    }
}

window.customElements.define('gallery-component', GalleryComponent);
