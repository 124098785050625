import {css, html} from 'lit-element';
import { Textfield } from '@spectrum-web-components/textfield';
import {ifDefined, live} from "@spectrum-web-components/base";
/**
 * An input element.
 */
class StepperComponent extends Textfield {


    // static get properties() {
    //     return {
    //
    //     };
    // }

    constructor() {
        super();
        //this.events();
    }

    attributeChangedCallback(name, oldval, newval) {
        console.log('InputComponent attribute change: ', name, newval);
        super.attributeChangedCallback(name, oldval, newval);
    }

    // createRenderRoot() {
    //     return this;
    // }

    firstUpdated(_changedProperties) {
        console.log('firstUpdate:', _changedProperties);
    }


    _onInput(e) {
        //this.value = e.target.value;
        let change = new CustomEvent('value-change', {detail: e.target.value});
        console.log('dispatching data:', e.target.value);
        this.dispatchEvent(change);
    }

    _onChange(e) {
        console.log('color on change1:', e.target.value);
        console.log('color on change2:', this.value);
        //this.val = e.target.value;
        //let change = new Event('change');
        //this.dispatchEvent(change);
    }

    async fetchData() {

    }
    onInput() {
        this.value = this.inputElement.value;
    }
    get renderInput() {
        return html `
           
            <input
                type="number"
                id="input"
                placeholder=${this.placeholder}
                .value=${live(this.value)}
                @change=${this.onChange}
                @input=${this.onInput}
                style="-moz-appearance: none;"
                min="1"
            />
        `;
    }
}
customElements.define('sp-stepper', StepperComponent);

